import React, { useEffect, useState, useContext } from 'react';
import useTranslations from '../hooks/useTranslations';
import PrintAccountSheet from '../components/PrintAccountSheet';
import { AppContext } from '../context/AppContext'; // Import the context

const PrintPage = () => {
  const { setLanguageCode } = useContext(AppContext); // Use context to set language code
  const { t } = useTranslations();
  const [data, setData] = useState(null);

  // Retrieve data from localStorage and remove it once set
  useEffect(() => {
    const storedData = localStorage.getItem('accountSheetsData');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setData(parsedData);

      // Set the language code from localStorage
      if (parsedData.languageCode) {
        setLanguageCode(parsedData.languageCode);
      }

      localStorage.removeItem('accountSheetsData'); // Remove the data from localStorage
    }
  }, [setLanguageCode]);

  // Trigger window.print() after data is set
  useEffect(() => {
    if (data && data.accountSheets && data.accountSheets.length > 0) {
      setTimeout(() => {
        window.print(); // Open the print dialog
      }, 500); // Delay to ensure page content is rendered before printing
    }
  }, [data]);

  if (!data || !data.accountSheets || data.accountSheets.length === 0) {
    return <p>{t('NO_ACCOUNT_SHEETS')}</p>;
  }

  const { accountSheets, leftImage, rightImage, watermarkImage } = data;

  const getImageSource = (image) => {
    if (!image) return null;
    return image.type === 'uploaded' ? image.data : image.data; // Base64 or URL
  };

  return (
    <div className="print-page relative">
      <h1 className="text-3xl font-bold text-center mb-8">{t('ACCOUNT_SHEETS')}</h1>

      {accountSheets.map((sheet) => (
        <PrintAccountSheet key={sheet.id} sheet={sheet} />
      ))}

      {/* Left Bottom Image */}
      {getImageSource(leftImage) && (
        <div className="left-image-container absolute bottom-0 left-0">
          <img src={getImageSource(leftImage)} alt="" className="w-auto h-24" />
        </div>
      )}

      {/* Right Bottom Image */}
      {getImageSource(rightImage) && (
        <div className="right-image-container absolute bottom-0 right-0">
          <img src={getImageSource(rightImage)} alt="" className="w-auto h-24" />
        </div>
      )}

      {/* Watermark Image */}
      {getImageSource(watermarkImage) && (
        <div className="watermark-image-container absolute inset-0 flex justify-center items-center opacity-10">
          <img src={getImageSource(watermarkImage)} alt="" className="w-full h-full object-contain" />
        </div>
      )}
    </div>
  );
};

export default PrintPage;