import React from 'react';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const AdminTopMenu = () => {
  const location = useLocation();

  return (
    <nav className="flex items-center w-screen justify-center p-4 bg-gradient-to-r from-blue-500 to-blue-400 text-white shadow-md">
      <ul className="flex space-x-8">
        <li>
          <NavLink 
            to="/admin" 
            exact
            className={`text-lg font-medium hover:text-blue-200 transition-colors ${
  location.pathname === '/admin' ? 'border-b-2 border-white' : ''
}`}
          >
            Dashboard
          </NavLink>
        </li>
        <li>
          <NavLink 
            to="/admin/brands" 
            className={`text-lg font-medium hover:text-blue-200 transition-colors ${
  location.pathname.startsWith('/admin/brands') ? 'border-b-2 border-white' : ''
}`}
          >
            Manage Brands
          </NavLink>
        </li>
        <li>
          <NavLink 
            to="/admin/translations" 
            className={`text-lg font-medium hover:text-blue-200 transition-colors ${
  location.pathname.startsWith('/admin/translations') ? 'border-b-2 border-white' : ''
}`}
          >
            Manage Translations
          </NavLink>
        </li>
        <li>
          <NavLink 
            to="/admin/themes" 
            className={`text-lg font-medium hover:text-blue-200 transition-colors ${
  location.pathname.startsWith('/admin/themes') ? 'border-b-2 border-white' : ''
}`}
          >
            Manage Themes
          </NavLink>
        </li>
        {/* Add more menu items as needed */}
      </ul>
    </nav>
  );
};

export default AdminTopMenu;