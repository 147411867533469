// context/BrandsContext.js
import React, { createContext } from 'react';
import useBrands from '../hooks/useBrands';

export const BrandsContext = createContext();

export const BrandsProvider = ({ children }) => {
  const brandsData = useBrands();

  return (
    <BrandsContext.Provider value={brandsData}>
      {children}
    </BrandsContext.Provider>
  );
};