import React from 'react';
import useTranslations from '../../hooks/useTranslations';

const LicenseInput = ({ field, value, onChange, template }) => {
    const { t } = useTranslations();
  // Extract custom validation and format rules from the template
  const pattern = template?.validation?.pattern || '^[A-Z0-9-]+$';
  const format = template?.validation?.format || 'XXXXX-XXXXX-XXXXX-XXXXX-XXXXX';

  const handleChange = (e) => {
    let inputValue = e.target.value.replace(/[^A-Z0-9]/gi, ''); // Remove non-alphanumeric chars

    // Auto-format the input value based on the format specified in the template
    const formattedValue = inputValue
      .match(/.{1,5}/g)?.join('-')
      .substring(0, format.length);

    onChange({
      target: {
        name: field.name,
        value: formattedValue
      }
    });
  };

  return (
    <div className="form-group">
      <label htmlFor={field.name} className="block text-sm font-medium text-gray-700">
      {t(field.name)}
      </label>
      <input
        type="text"
        id={field.name}
        value={value || ''}
        onChange={handleChange}
        pattern={pattern}  // Use pattern for validation
        placeholder={format}  // Use format as a placeholder
        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
      />
    </div>
  );
};

export default LicenseInput;