import React, { useState, useEffect } from 'react';
import useThemes from '../../hooks/useThemes';
import { FaTimes } from 'react-icons/fa';

const ThemeForm = ({ theme, onClose }) => {
  const { createTheme, updateTheme } = useThemes();

  const [formData, setFormData] = useState({
    name: '',
    languageCode: 'en',
    headerColor: '',
    leftImage: '',
    rightImage: '',
    watermarkImage: '',
    logo: '', // Added logo field
  });

  useEffect(() => {
    if (theme) {
      setFormData({
        name: theme.name || '',
        languageCode: theme.languageCode || 'en',
        headerColor: theme.headerColor || '',
        leftImage: theme.leftImage || '',
        rightImage: theme.rightImage || '',
        watermarkImage: theme.watermarkImage || '',
        logo: theme.logo || '', // Populate logo field if editing
      });
    }
  }, [theme]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const action = theme ? updateTheme(theme.id, formData) : createTheme(formData);

    action
      .then(() => {
        onClose();
      })
      .catch((error) => {
        console.error('Error saving theme:', error);
        alert('An error occurred while saving the theme. Please try again.');
      });
  };

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg w-full max-w-lg mx-auto p-6 relative shadow-xl">
        {/* Modal Header */}
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-semibold">
            {theme ? 'Edit Theme' : 'Add Theme'}
          </h3>
          <button onClick={onClose} className="text-gray-600 hover:text-gray-800">
            <FaTimes className="w-6 h-6" />
          </button>
        </div>

        {/* Form Content */}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="theme-name" className="block text-sm font-medium text-gray-700">
              Name:
            </label>
            <input
              type="text"
              id="theme-name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              placeholder="Enter theme name"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="theme-languageCode" className="block text-sm font-medium text-gray-700">
              Language Code:
            </label>
            <select
              id="theme-languageCode"
              name="languageCode"
              value={formData.languageCode}
              onChange={handleChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            >
              <option value="en">English</option>
              <option value="no">Norsk</option>
            </select>
          </div>
          <div className="mb-4">
            <label htmlFor="headerColor" className="block text-sm font-medium text-gray-700">
              Header Color:
            </label>
            <input
              type="text"
              id="headerColor"
              name="headerColor"
              value={formData.headerColor}
              onChange={handleChange}
              placeholder="#000000"
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="leftImage" className="block text-sm font-medium text-gray-700">
              Left Image URL:
            </label>
            <input
              type="text"
              id="leftImage"
              name="leftImage"
              value={formData.leftImage}
              onChange={handleChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              placeholder="Enter left image URL"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="rightImage" className="block text-sm font-medium text-gray-700">
              Right Image URL:
            </label>
            <input
              type="text"
              id="rightImage"
              name="rightImage"
              value={formData.rightImage}
              onChange={handleChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              placeholder="Enter right image URL"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="watermarkImage" className="block text-sm font-medium text-gray-700">
              Watermark Image URL:
            </label>
            <input
              type="text"
              id="watermarkImage"
              name="watermarkImage"
              value={formData.watermarkImage}
              onChange={handleChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              placeholder="Enter watermark image URL"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="logo" className="block text-sm font-medium text-gray-700">
              Logo URL:
            </label>
            <input
              type="text"
              id="logo"
              name="logo"
              value={formData.logo}
              onChange={handleChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              placeholder="Enter logo image URL"
            />
          </div>

          {/* Form Actions */}
          <div className="flex justify-end space-x-4 mt-6">
            <button
              type="submit"
              className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition duration-200"
            >
              {theme ? 'Update' : 'Create'}
            </button>
            <button
              type="button"
              onClick={onClose}
              className="bg-gray-300 text-gray-800 px-4 py-2 rounded-md hover:bg-gray-400 transition duration-200"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ThemeForm;