import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../context/AppContext';
import { Link, useLocation } from 'react-router-dom';
import useTranslations from '../hooks/useTranslations';
import { FaBars, FaTimes } from 'react-icons/fa';
import useThemes from '../hooks/useThemes';

const Header = () => {
  const {
    languageCode, setLanguageCode, headerColor, setHeaderColor, logo, setLogo,
    setLeftImage, setRightImage, setWatermarkImage
  } = useContext(AppContext); // Use context for images
  const { t } = useTranslations();
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState('');
  const { themes, fetchThemes } = useThemes();

  useEffect(() => {
    fetchThemes();
    
    // Load saved theme from localStorage
    const savedTheme = localStorage.getItem('selectedTheme');
    if (savedTheme) {
      const parsedTheme = JSON.parse(savedTheme);
      setSelectedTheme(parsedTheme.id);
      applyTheme(parsedTheme);
    }
  }, []);

  // Apply the selected theme to the context
  const applyTheme = (theme) => {
    setLanguageCode(theme.languageCode);
    setHeaderColor(theme.headerColor);
    setLogo(theme.logo);
    setLeftImage(theme.leftImage);
    setRightImage(theme.rightImage);
    setWatermarkImage(theme.watermarkImage);
  };

  // Handle theme selection
  const handleThemeChange = (event) => {
    const themeId = event.target.value;
    setSelectedTheme(themeId);

    // Find the selected theme data and update context
    const selected = themes.find((theme) => theme.id === parseInt(themeId, 10));
    if (selected) {
      applyTheme(selected);

      // Save the selected theme to localStorage
      localStorage.setItem('selectedTheme', JSON.stringify(selected));
    }
  };

  return (
    <header
      className="flex justify-between items-center p-4 text-white sticky top-0 z-50 shadow-lg"
      style={{ backgroundColor: headerColor }}
    >
      <div className="flex items-center">
        <Link to="/" className="flex items-center hover:text-gray-200">
          <img src={logo} alt="Logo" className="h-12 w-auto mr-3" />
          <span className="text-3xl font-extrabold tracking-tight uppercase">{t('ACCOUNT_SHEETS')}</span>
        </Link>
      </div>
      <div className="flex items-center">
        <button
          className="lg:hidden focus:outline-none mr-4 text-white"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          {menuOpen ? <FaTimes className="w-8 h-8" /> : <FaBars className="w-8 h-8" />}
        </button>
        <nav className={`lg:flex flex-col lg:flex-row items-center space-y-2 lg:space-y-0 lg:space-x-6 ${menuOpen ? 'block' : 'hidden'} lg:block absolute lg:static top-16 left-0 w-full lg:w-auto bg-white lg:bg-transparent lg:border-none border-t border-gray-300 lg:p-0 p-4 text-gray-800 lg:text-white shadow-md lg:shadow-none`}>
          <Link to="/" className={`block px-4 py-2 lg:p-0 hover:text-yellow-300 transition-colors ${location.pathname === '/' ? 'font-bold border-b-2 border-yellow-300' : ''}`} onClick={() => setMenuOpen(false)}>
            {t('HOME')}
          </Link>
          <Link to="/admin" className={`block px-4 py-2 lg:p-0 hover:text-yellow-300 transition-colors ${location.pathname.startsWith('/admin') ? 'font-bold border-b-2 border-yellow-300' : ''}`} onClick={() => setMenuOpen(false)}>
            {t('ADMIN')}
          </Link>
        </nav>
        <select value={languageCode} onChange={(e) => setLanguageCode(e.target.value)} className="ml-4 bg-white text-gray-800 border border-gray-300 rounded-full p-2 focus:outline-none focus:border-indigo-700">
          <option value="en">English</option>
          <option value="no">Norsk</option>
        </select>
        <select value={selectedTheme} onChange={handleThemeChange} className="ml-4 bg-white text-gray-800 border border-gray-300 rounded-full p-2 focus:outline-none focus:border-indigo-700">
          {themes.map((theme) => (
            <option key={theme.id} value={theme.id}>
              {theme.name}
            </option>
          ))}
        </select>
      </div>
    </header>
  );
};

export default Header;