import React from 'react';
import useTranslations from '../hooks/useTranslations';
import PrintPatternDisplay from './PrintPatternDisplay'; // Import the print-friendly pattern component

const PrintAccountSheet = ({ sheet }) => {
  const { t } = useTranslations();
  const { brand, fields } = sheet;

  return (
    <div className="print-account-sheet mb-6">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-xl font-semibold">{t(brand.name)}</h3>
        {brand.logo_url && (
          <img src={brand.logo_url} alt={`${brand.name} Logo`} className="h-10 w-auto" />
        )}
      </div>

      <div className="fields">
        {fields.map((field) => {
          if (field.type === 'pattern') {
            return <PrintPatternDisplay key={field.name} field={field} value={sheet.data[field.name]} />;
          }

          // For other field types, just display the value
          return (
            <div key={field.name} className="mb-2">
              <strong>{t(field.name)}:</strong> {sheet.data[field.name] || 'N/A'}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PrintAccountSheet;