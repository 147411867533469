import React, { useState, useEffect, useContext } from 'react';
import BrandForm from './BrandForm';
import { BrandsContext } from '../../context/BrandsContext';
import { FaPlus, FaEdit, FaTrash } from 'react-icons/fa';

const ManageBrands = () => {
  const {
    brands,
    loading,
    error,
    fetchBrands,
    deleteBrand,
  } = useContext(BrandsContext);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [showForm, setShowForm] = useState(false);

  const handleAddBrand = () => {
    setSelectedBrand(null);
    setShowForm(true);
  };

  const handleEditBrand = (brand) => {
    setSelectedBrand(brand);
    setShowForm(true);
  };

  const handleDeleteBrand = (brandId) => {
    if (window.confirm('Are you sure you want to delete this brand?')) {
      deleteBrand(brandId)
        .then(() => {
          // Optionally show a success message
        })
        .catch((error) => {
          // Handle error, e.g., show a notification
          console.error('Error deleting brand:', error);
        });
    }
  };

  const handleFormClose = () => {
    setShowForm(false);
    setSelectedBrand(null);
    fetchBrands(); // Refresh the brands list
  };

  if (loading) {
    return <div className="text-center text-blue-500">Loading brands...</div>;
  }

  if (error) {
    return <div className="text-center text-red-500">Error loading brands: {error.message}</div>;
  }

  return (
    <div className="p-8 bg-white rounded-lg shadow-lg">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-gray-800">Manage Brands</h2>
        <button 
          onClick={handleAddBrand} 
          className="flex items-center bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition duration-300"
        >
          <FaPlus className="mr-2" /> Add Brand
        </button>
      </div>
      <div className="overflow-x-auto overflow-y-auto max-h-96">
        <table className="min-w-full bg-white">
          <thead>
            <tr className="w-full bg-gray-200 text-left">
              <th className="p-4 font-semibold text-gray-700">ID</th>
              <th className="p-4 font-semibold text-gray-700">Name</th>
              <th className="p-4 font-semibold text-gray-700">Logo URL</th>
              <th className="p-4 font-semibold text-gray-700">Parent Brand</th>
              <th className="p-4 font-semibold text-gray-700">Fields</th>
              <th className="p-4 font-semibold text-gray-700">Actions</th>
            </tr>
          </thead>
          <tbody>
            {brands.map((brand) => (
              <tr key={brand.id} className="hover:bg-gray-100 transition duration-300">
                <td className="p-4 border-b border-gray-200 text-gray-800">{brand.id}</td>
                <td className="p-4 border-b border-gray-200 text-gray-800">{brand.name}</td>
                <td className="p-4 border-b border-gray-200 text-gray-800 truncate max-w-xs">{brand.logo_url}</td>
                <td className="p-4 border-b border-gray-200 text-gray-800">{brand.parentBrand ? brand.parentBrand.name : '-'}</td>
                <td className="p-4 border-b border-gray-200 text-gray-800">
                  {brand.fields && brand.fields.length > 0
                    ? brand.fields.map((field) => field.label).join(', ')
                    : '-'}
                </td>
                <td className="p-4 border-b border-gray-200 text-gray-800">
                  <button
                    onClick={() => handleEditBrand(brand)}
                    className="flex items-center text-yellow-500 hover:text-yellow-600 mr-4"
                  >
                    <FaEdit className="mr-1" /> Edit
                  </button>
                  <button
                    onClick={() => handleDeleteBrand(brand.id)}
                    className="flex items-center text-red-500 hover:text-red-600"
                  >
                    <FaTrash className="mr-1" /> Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showForm && (
        <div className="mt-6">
          <BrandForm brand={selectedBrand} onClose={handleFormClose} />
        </div>
      )}
    </div>
  );
};

export default ManageBrands;