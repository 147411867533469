// components/Sidebar.js
import React, { useState, useEffect } from 'react';
import BrandItem from './BrandItem';
import useTranslations from '../hooks/useTranslations';
import useBrands from '../hooks/useBrands';

const Sidebar = ({ onBrandClick }) => {
  const { t } = useTranslations();
  const { brands, loading } = useBrands();
  const [hoveredBrandId, setHoveredBrandId] = useState(null);
  const [delayedHoverId, setDelayedHoverId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    let timer;
    if (hoveredBrandId) {
      timer = setTimeout(() => {
        setDelayedHoverId(hoveredBrandId);
      }, 300); // Increased delay to 300ms to make it easier to reach the submenu
    } else {
      timer = setTimeout(() => {
        setDelayedHoverId(null);
      }, 300); // Delay hiding the submenu to make it easier to reach
    }

    return () => {
      clearTimeout(timer);
    };
  }, [hoveredBrandId]);

  if (loading) {
    return (
      <div className="sidebar">
        <p>{t('LOADING')}</p>
      </div>
    );
  }

  // Filter brands based on search term (including both main brands and sub-brands)
  const filteredBrands = brands.filter(
    (brand) =>
      brand.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      brands.some(
        (subBrand) =>
          subBrand.parent_id === brand.id &&
          subBrand.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
  );

  // Filter main brands with no parent (parent_id is null)
  const mainBrands = filteredBrands.filter((brand) => !brand.parent_id);

  return (
    <aside className="w-64 p-4 bg-gray-100 border-r border-gray-300 z-50">
      <h2 className="text-lg font-semibold mb-4">{t('SELECT_BRAND')}</h2>
      <input
        type="text"
        placeholder={t('SEARCH_BRANDS')}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="w-full p-2 mb-4 border border-gray-300 rounded"
      />
      <div className="space-y-2 z-50">
        {mainBrands.map((brand) => (
          <div
            key={brand.id}
            className="relative group"
            onMouseEnter={() => setHoveredBrandId(brand.id)}
            onMouseLeave={() => setHoveredBrandId(null)}
          >
            <BrandItem brand={brand} onClick={() => onBrandClick(brand.id)} />
            {/* Display sub-brands on hover */}
            {delayedHoverId === brand.id && brand.has_sub_brands && (
              <div
                className="absolute left-full top-0 ml-1 w-56 bg-white border border-gray-300 shadow-lg p-2"
                onMouseEnter={() => setHoveredBrandId(brand.id)}
                onMouseLeave={() => setHoveredBrandId(null)}
              >
                {brands
                  .filter((subBrand) => subBrand.parent_id === brand.id)
                  .map((subBrand) => (
                    <div
                      key={subBrand.id}
                      onClick={() => onBrandClick(subBrand.id)}
                      className="flex items-center space-x-2 cursor-pointer p-2 hover:bg-gray-100 z-100"
                    >
                      <img
                        src={subBrand.logo_url}
                        alt={subBrand.name}
                        className="h-6 w-auto"
                      />
                      <span>{t(`${subBrand.name}`)}</span>
                    </div>
                  ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </aside>
  );
};

export default Sidebar;