import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from './Header';

const Layout = ({ children }) => {
  const location = useLocation();
  
  // List of routes where the layout should NOT be applied (e.g., '/print')
  const noLayoutRoutes = ['/print'];

  // If the current route matches any in noLayoutRoutes, don't render the layout
  const shouldRenderLayout = !noLayoutRoutes.includes(location.pathname);

  return (
    <div className="app-container">
      {/* Conditionally render Header */}
      {shouldRenderLayout && <Header />}
      
      {/* Main Content */}
      <div className="main-content">
        {children}
      </div>
    </div>
  );
};

export default Layout;