// hooks/useFields.js
import { useState, useEffect } from 'react';
import axiosInstance from '../utils/axiosInstance';

const useFields = () => {
  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch fields on component mount
  useEffect(() => {
    fetchFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Function to fetch all fields
  const fetchFields = () => {
    setLoading(true);
    axiosInstance
      .get('/api/fields')
      .then((response) => {
        setFields(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching fields:', error);
        setError(error);
        setLoading(false);
      });
  };

  return {
    fields,
    loading,
    error,
    fetchFields,
  };
};

export default useFields;