import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AdminDashboard from '../components/admin/AdminDashboard';
import ManageBrands from '../components/admin/ManageBrands';
import ManageTranslations from '../components/admin/ManageTranslations';
import ManageThemes from '../components/admin/ManageThemes';
import AdminTopMenu from '../components/admin/AdminTopMenu';
import { BrandsProvider } from '../context/BrandsContext';
import ProtectedRoute from '../components/ProtectedRoute'; // Import the ProtectedRoute component

const AdminPage = () => {
  return (
    <BrandsProvider>
      <div className="admin-page">
        <AdminTopMenu />
        {/* Wrap Routes in ProtectedRoute */}
        <ProtectedRoute>
          <Routes>
            <Route path="/" element={<AdminDashboard />} />
            <Route path="brands" element={<ManageBrands />} />
            <Route path="translations" element={<ManageTranslations />} />
            <Route path="themes" element={<ManageThemes />} />
            {/* Add more admin routes as needed */}
          </Routes>
        </ProtectedRoute>
      </div>
    </BrandsProvider>
  );
};

export default AdminPage;