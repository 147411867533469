import React from 'react';
import { FaFolderOpen } from 'react-icons/fa'; // Import a nice icon
import useTranslations from '../hooks/useTranslations';

const EmptyState = ({ message }) => {
  const { t } = useTranslations(); // Get the translation function

  return (
    <div className="flex flex-col items-center justify-center h-full text-center py-20 bg-gray-100 rounded-lg">
      <FaFolderOpen className="text-6xl text-gray-400 mb-4" /> {/* Icon */}
      <h2 className="text-2xl font-bold text-gray-700 mb-2">{t('NO_SHEETS')}</h2> {/* Big Text */}
      <p className="text-lg text-gray-500">{message}</p> {/* Optional additional message */}
    </div>
  );
};

export default EmptyState;