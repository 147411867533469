import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import useTranslations from '../hooks/useTranslations';
import Sidebar from '../components/Sidebar';
import AccountSheet from '../components/AccountSheet';
import Optionbar from '../components/Optionbar';
import EmptyState from '../components/EmptyState';
import axiosInstance from '../utils/axiosInstance';
import { AppContext } from '../context/AppContext'; // Use context for theme images
import { FaTrash, FaFileExport, FaPrint } from 'react-icons/fa';

const HomePage = () => {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const [accountSheets, setAccountSheets] = useState([]);
  
  // Access theme image context (logo, leftImage, rightImage, watermarkImage)
  const { leftImage: themeLeftImage, rightImage: themeRightImage, watermarkImage: themeWatermarkImage, languageCode } = useContext(AppContext);
  
  const [leftImage, setLeftImage] = useState(themeLeftImage || null); // State for the left bottom image
  const [rightImage, setRightImage] = useState(themeRightImage || null); // State for the right bottom image
  const [watermarkImage, setWatermarkImage] = useState(themeWatermarkImage || null); // State for the watermark image

  // Handle brand selection
  const addAccountSheet = (brandId) => {
    Promise.all([
      axiosInstance.get(`/api/brands/${brandId}`),
      axiosInstance.get(`/api/brands/${brandId}/fields`),
    ])
      .then(([brandResponse, fieldsResponse]) => {
        const brandData = brandResponse.data;
        const fieldsData = fieldsResponse.data;
        const sortedFields = fieldsData.sort((a, b) => a.BrandField.order - b.BrandField.order);

        setAccountSheets((prevSheets) => [
          ...prevSheets,
          {
            id: Date.now(),
            brandId: brandId,
            brand: brandData,
            fields: sortedFields,
            data: {},
          },
        ]);
      })
      .catch((error) => {
        console.error('Error fetching brand data or fields:', error);
      });
  };

  // Handle removing an account sheet
  const removeAccountSheet = (id) => {
    setAccountSheets((prevSheets) => prevSheets.filter((sheet) => sheet.id !== id));
  };

  // Handle input changes for each account sheet
  const handleSheetInputChange = (e, sheetId) => {
    const { name, value } = e.target;
    setAccountSheets((prevSheets) =>
      prevSheets.map((sheet) =>
        sheet.id === sheetId ? { ...sheet, data: { ...sheet.data, [name]: value } } : sheet
      )
    );
  };

  // Handle clearing all account sheets
  const clearAccountSheets = () => {
    setAccountSheets([]);
    setLeftImage(themeLeftImage || null);
    setRightImage(themeRightImage || null);
    setWatermarkImage(themeWatermarkImage || null);
  };

  // Handle exporting account sheets as JSON
  const exportAccountSheets = () => {
    const sheetData = accountSheets.map((sheet) => ({
      brand: sheet.brand.name,
      fields: sheet.fields.map((field) => ({
        name: field.name,
        value: sheet.data[field.name] || '',
      })),
    }));

    const blob = new Blob([JSON.stringify(sheetData, null, 2)], { type: 'application/json' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'account_sheets.json';
    link.click();
  };

  // Handle printing account sheets to a new tab
  const handlePrint = () => {
    const accountData = JSON.stringify({
      accountSheets,
      leftImage: leftImage ? { type: 'uploaded', data: leftImage } : { type: 'url', data: themeLeftImage },
      rightImage: rightImage ? { type: 'uploaded', data: rightImage } : { type: 'url', data: themeRightImage },
      watermarkImage: watermarkImage ? { type: 'uploaded', data: watermarkImage } : { type: 'url', data: themeWatermarkImage },
      languageCode // Add the selected language
    });
  
    localStorage.setItem('accountSheetsData', accountData);
    window.open('/print', '_blank');
  };

  return (
    <div className="home-page flex">
      {/* Sidebar */}
      <Sidebar onBrandClick={addAccountSheet} />
      
      {/* Content Area */}
      <main className="content-area flex-1 p-4">
        {accountSheets.length === 0 ? (
          <EmptyState />
        ) : (
          accountSheets.map((sheet) => (
            <AccountSheet
              key={sheet.id}
              sheet={sheet}
              onInputChange={handleSheetInputChange}
              onRemove={removeAccountSheet}
            />
          ))
        )}
      </main>
      
      {/* Optionbar with image upload */}
      <Optionbar
        onClear={clearAccountSheets}
        onExport={exportAccountSheets}
        onPrint={handlePrint}
        onUploadLeftImage={setLeftImage}
        onUploadRightImage={setRightImage}
        onUploadWatermarkImage={setWatermarkImage}
        leftImagePreview={leftImage}
        rightImagePreview={rightImage}
        watermarkImagePreview={watermarkImage}
      />
    </div>
  );
};

export default HomePage;