import { useState, useEffect, useContext } from 'react';
import axiosInstance from '../utils/axiosInstance';
import { AppContext } from '../context/AppContext';

const useTranslations = () => {
  const { languageCode } = useContext(AppContext);
  const [translations, setTranslations] = useState({});
  const [keys, setKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch translations for the current language
  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get(`/api/translations/${languageCode}`)
      .then((response) => {
        const translationMap = {};
        response.data.forEach(({ key, value }) => {
          translationMap[key] = value;
        });
        setTranslations(translationMap);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching translations:', error);
        setError(error);
        setLoading(false);
      });
  }, [languageCode]);

  // Function to fetch all unique keys
  const fetchUniqueKeys = () => {
    setLoading(true);
    axiosInstance
      .get('/api/translations/keys')
      .then((response) => {
        setKeys(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching unique keys:', error);
        setError(error);
        setLoading(false);
      });
  };

  // Function to add or update a translation
  const addOrUpdateTranslation = async ({ language_code, key, value }) => {
    setLoading(true);
    try {
      // Check if the translation for the key already exists
      const response = await axiosInstance.get(`/api/translations/${language_code}`);
      const existingTranslation = response.data.find((item) => item.key === key);

      if (existingTranslation) {
        // If it exists, update the translation
        await axiosInstance.put(`/api/translations/${existingTranslation.id}`, {
          language_code,
          key,
          value,
        });
      } else {
        // If it does not exist, create a new translation
        await axiosInstance.post('/api/translations', {
          language_code,
          key,
          value,
        });
      }
      setLoading(false);
    } catch (error) {
      console.error('Error adding/updating translation:', error);
      setError(error);
      setLoading(false);
    }
  };

  const t = (key) => translations[key] || key;

  return {
    t,
    keys,
    loading,
    error,
    fetchUniqueKeys,
    addOrUpdateTranslation,
  };
};

export default useTranslations;