import React from 'react';
import useTranslations from '../../hooks/useTranslations';

const PinInput = ({ field, value, onChange }) => {
  const { t } = useTranslations();

  return (
    <div className="form-group">
      <label className="form-label">
        {t(field.name)}
        <input
          type="password"
          inputMode="numeric"
          name={field.name}
          value={value || ''}
          onChange={onChange}
          required={field.required}
          className="form-input"
        />
      </label>
    </div>
  );
};

export default PinInput;