import React, { useState } from 'react';
import './PatternInput.css'; // Import CSS for styling
import useTranslations from '../../hooks/useTranslations';

const PatternInput = ({ field, value, onChange }) => {
  const { t } = useTranslations();
  const [pattern, setPattern] = useState(value || []);
  const [isDrawing, setIsDrawing] = useState(false);

  // Positions of the dots in a 3x3 grid
  const dots = [
    { id: 1, x: 50, y: 50 },
    { id: 2, x: 150, y: 50 },
    { id: 3, x: 250, y: 50 },
    { id: 4, x: 50, y: 150 },
    { id: 5, x: 150, y: 150 },
    { id: 6, x: 250, y: 150 },
    { id: 7, x: 50, y: 250 },
    { id: 8, x: 150, y: 250 },
    { id: 9, x: 250, y: 250 },
  ];

  const handleMouseDown = () => {
    setPattern([]);
    setIsDrawing(true);
  };

  const handleMouseUp = () => {
    setIsDrawing(false);
    onChange({
      target: {
        name: field.name,
        value: pattern,
      },
    });
  };

  const handleMouseMove = (e) => {
    if (!isDrawing) return;

    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    dots.forEach((dot) => {
      const dx = x - dot.x;
      const dy = y - dot.y;
      const distance = Math.sqrt(dx * dx + dy * dy);

      if (distance < 20 && !pattern.includes(dot.id)) {
        setPattern((prevPattern) => [...prevPattern, dot.id]);
      }
    });
  };

  return (
    <div className="form-group">
      <label className="form-label">{t(field.name)}</label>
      <svg
        width="300"
        height="300"
        className="pattern-lock"
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseUp}
      >
        {/* Lines */}
        {pattern.map((dotId, index) => {
          if (index === 0) return null;
          const fromDot = dots.find((d) => d.id === pattern[index - 1]);
          const toDot = dots.find((d) => d.id === dotId);
          return (
            <line
              key={`line-${index}`}
              x1={fromDot.x}
              y1={fromDot.y}
              x2={toDot.x}
              y2={toDot.y}
              stroke="blue"
              strokeWidth="5"
            />
          );
        })}
        {/* Dots */}
        {dots.map((dot) => {
          const indexInPattern = pattern.indexOf(dot.id);
          let fillColor = '#ccc'; // Default color for unselected dots

          if (indexInPattern !== -1) {
            if (indexInPattern === 0) {
              fillColor = 'green'; // Starting dot
            } else if (indexInPattern === pattern.length - 1) {
              fillColor = 'red'; // Ending dot
            } else {
              fillColor = 'blue'; // Intermediate dots
            }
          }

          return (
            <g key={`dot-${dot.id}`}>
              <circle
                cx={dot.x}
                cy={dot.y}
                r="15"
                fill={fillColor}
              />
            </g>
          );
        })}
      </svg>
    </div>
  );
};

export default PatternInput;