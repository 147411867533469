import React, { createContext, useState } from 'react';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [languageCode, setLanguageCode] = useState('en');
  const [headerColor, setHeaderColor] = useState('#4F46E5'); // Default header color (indigo)
  const [logo, setLogo] = useState('/images/themes/default/logo.png');
  const [leftImage, setLeftImage] = useState(null); // Add left image
  const [rightImage, setRightImage] = useState(null); // Add right image
  const [watermarkImage, setWatermarkImage] = useState(null); // Add watermark image

  return (
    <AppContext.Provider value={{
      languageCode,
      setLanguageCode,
      headerColor,
      setHeaderColor,
      logo,
      setLogo,
      leftImage,
      setLeftImage,
      rightImage,
      setRightImage,
      watermarkImage,
      setWatermarkImage, // Add function to update the images
    }}>
      {children}
    </AppContext.Provider>
  );
};