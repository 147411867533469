import React, { useState, useEffect, useContext } from 'react';
import { BrandsContext } from '../../context/BrandsContext';
import useFields from '../../hooks/useFields';
import './BrandForm.css';

const BrandForm = ({ brand, onClose }) => {
  const {
    brands,
    loading: brandsLoading,
    error: brandsError,
    addBrand,
    updateBrand,
  } = useContext(BrandsContext);

  const {
    fields: availableFields,
    loading: fieldsLoading,
    error: fieldsError,
  } = useFields();

  const [formData, setFormData] = useState({
    name: '',
    logo: null,  // File input
    parent_id: '',
    template: '',
  });

  const [selectedFields, setSelectedFields] = useState([]);
  const [errors, setErrors] = useState({}); // Store validation errors
  const [submitError, setSubmitError] = useState(null); // Store form submission error

  useEffect(() => {
    if (brand) {
      setFormData({
        name: brand.name || '',
        logo: null, // Reset the file input
        parent_id: brand.parent_id ? brand.parent_id.toString() : '',
        template: brand.template || '',
      });

      setSelectedFields(
        brand.fields
          .map((field) => ({
            ...field,
            order: field.BrandField.order,
          }))
          .sort((a, b) => a.order - b.order)
      );
    } else {
      setFormData({
        name: '',
        logo: null,
        parent_id: '',
        template: '',
      });
      setSelectedFields([]);
    }
  }, [brand]);

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = 'Brand name is required.';
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  // Handle text input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: null }); // Clear error on change
  };

  // Handle file input changes
  const handleFileChange = (e) => {
    setFormData({ ...formData, logo: e.target.files[0] });
    setErrors({ ...errors, logo: null }); // Clear error on file change
  };

  const handleFieldSelection = (fieldId) => {
    setSelectedFields((prevFields) => {
      const fieldIndex = prevFields.findIndex((field) => field.id === fieldId);
      if (fieldIndex !== -1) {
        const updatedFields = [...prevFields];
        updatedFields.splice(fieldIndex, 1);
        return updatedFields;
      } else {
        const field = availableFields.find((f) => f.id === fieldId);
        return [...prevFields, { ...field, order: prevFields.length + 1 }];
      }
    });
  };

  const handleFieldOrderChange = (fieldId, newOrder) => {
    setSelectedFields((prevFields) => {
      return prevFields.map((field) =>
        field.id === fieldId ? { ...field, order: parseInt(newOrder, 10) } : field
      );
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!formData.name.trim()) {
      alert('Please enter a brand name.');
      return;
    }
  
    const submitData = new FormData();
    submitData.append('name', formData.name);
  
    // Handle parent_id properly: append null if it's empty or invalid
    if (formData.parent_id && formData.parent_id !== "null") {
      submitData.append('parent_id', formData.parent_id);  // Only append if valid
    } else {
      submitData.append('parent_id', '');  // Append empty string or remove parent_id if not valid
    }
  
    submitData.append('template', formData.template);
  
    // If the user selected a file, append it to the form data
    if (formData.logo) {
      submitData.append('logo', formData.logo);
    }
  
    // Append selected fields
    selectedFields.forEach((field, index) => {
      submitData.append(`fields[${index}][id]`, field.id);
      submitData.append(`fields[${index}][order]`, field.order || 0);
    });
  
    console.log('FormData being sent:', Array.from(submitData.entries()));  // Debugging FormData content
  
    const action = brand ? updateBrand(brand.id, submitData) : addBrand(submitData);
  
    action
      .then(() => {
        onClose();
      })
      .catch((error) => {
        console.error('Error saving brand:', error);
        alert('An error occurred while saving the brand. Please try again.');
      });
  };

  return (
    <div className="brand-form-overlay">
      <div className="brand-form-container">
        <h3>{brand ? 'Edit Brand' : 'Add Brand'}</h3>
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="form-group">
            <label htmlFor="brand-name">Name:</label>
            <input
              type="text"
              id="brand-name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              className={`form-input ${errors.name ? 'error-border' : ''}`}
              placeholder="Enter brand name"
            />
            {errors.name && <p className="error-message">{errors.name}</p>}
          </div>

          <div className="form-group">
            <label htmlFor="logo-url">Logo:</label>
            <input
              type="file"
              id="logo"
              name="logo"
              onChange={handleFileChange}
              className="form-input"
            />
            {errors.logo && <p className="error-message">{errors.logo}</p>}
          </div>

          <div className="form-group">
            <label htmlFor="parent-brand">Parent Brand:</label>
            <select
              id="parent-brand"
              name="parent_id"
              value={formData.parent_id}
              onChange={handleChange}
              className="form-input"
            >
              <option value="">None</option>
              {brands
                .filter((b) => !brand || b.id !== brand.id)
                .map((b) => (
                  <option key={b.id} value={b.id}>
                    {b.name}
                  </option>
                ))}
            </select>
            {errors.parent_id && <p className="error-message">{errors.parent_id}</p>}
          </div>

          <div className="form-group">
            <label htmlFor="template">Template:</label>
            <textarea
              id="template"
              name="template"
              value={formData.template}
              onChange={handleChange}
              className="form-input"
              rows={4}
              placeholder="Enter template (optional)"
            />
            {errors.template && <p className="error-message">{errors.template}</p>}
          </div>

          <div className="form-group">
            <label>Fields:</label>
            {availableFields.length === 0 ? (
              <p>No fields available.</p>
            ) : (
              <div className="fields-list">
                {availableFields.map((field) => {
                  const isSelected = selectedFields.some((sf) => sf.id === field.id);
                  const fieldOrder = isSelected
                    ? selectedFields.find((sf) => sf.id === field.id).order
                    : '';

                  return (
                    <div key={field.id} className="field-item">
                      <div className="field-item-checkbox">
                        <input
                          type="checkbox"
                          id={`field-${field.id}`}
                          checked={isSelected}
                          onChange={() => handleFieldSelection(field.id)}
                        />
                        <label htmlFor={`field-${field.id}`}>{field.label}</label>
                      </div>
                      {isSelected && (
                        <div className="field-item-order">
                          <label htmlFor={`field-order-${field.id}`}>Order:</label>
                          <input
                            type="number"
                            id={`field-order-${field.id}`}
                            min="1"
                            value={fieldOrder}
                            onChange={(e) =>
                              handleFieldOrderChange(field.id, e.target.value)
                            }
                            className="field-order-input"
                          />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          {submitError && <p className="error-message form-error">{submitError}</p>}

          <div className="form-actions">
            <button type="submit" className="save-button">
              {brand ? 'Update' : 'Create'}
            </button>
            <button
              type="button"
              onClick={onClose}
              className="cancel-button"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BrandForm;