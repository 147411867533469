import React from 'react';
import { FaChevronRight } from 'react-icons/fa';
import useTranslations from '../hooks/useTranslations';

const BrandItem = ({ brand, onClick }) => {
  const { t } = useTranslations(); // Get the translation function

  return (
    <div
      onClick={onClick}
      className="flex items-center p-1 cursor-pointer border border-transparent hover:border-gray-300 hover:bg-gray-50 rounded transition-all"
    >
      <img src={brand.logo_url} alt={brand.name} className="h-10 w-auto mr-3" />
      {/* Translate brand.name only if it's a generic label */}
      <span className="flex-1">{t(brand.name)}</span>
      {brand.has_sub_brands && (
        <FaChevronRight className="text-gray-500 text-xl rounded-full bg-gray-200 p-1 mr-1" />
      )}
    </div>
  );
};

export default BrandItem;