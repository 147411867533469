// hooks/useBrands.js
import { useState, useEffect } from 'react';
import axiosInstance from '../utils/axiosInstance';

const useBrands = () => {
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch brands on component mount
  useEffect(() => {
    fetchBrands();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Function to fetch all brands
  const fetchBrands = () => {
    setLoading(true);
    axiosInstance
      .get('/api/brands')
      .then((response) => {
        setBrands(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching brands:', error);
        setError(error);
        setLoading(false);
      });
  };

// Function to add a new brand
const addBrand = (brandData) => {
  console.log(brandData);
  setLoading(true);
  return axiosInstance
    .post('/api/brands', brandData)
    .then((response) => {
      setBrands((prevBrands) => [...prevBrands, response.data]);
      setLoading(false);
      return response.data;
    })
    .catch((error) => {
      console.error('Error adding brand:', error);
      // Capture the error message from the backend if available
      const errorMsg = error?.response?.data?.message || 'An error occurred while adding the brand';
      setError(errorMsg);
      setLoading(false);
      throw error;
    });
};

// Function to update an existing brand
const updateBrand = (brandId, brandData) => {
  setLoading(true);
  return axiosInstance
    .put(`/api/brands/${brandId}`, brandData)
    .then((response) => {
      setBrands((prevBrands) =>
        prevBrands.map((brand) =>
          brand.id === brandId ? response.data : brand
        )
      );
      setLoading(false);
      return response.data;
    })
    .catch((error) => {
      console.error('Error updating brand:', error);
      const errorMsg = error?.response?.data?.message || 'An error occurred while updating the brand';
      setError(errorMsg);
      setLoading(false);
      throw error;
    });
};

  // Function to delete a brand
  const deleteBrand = (brandId) => {
    setLoading(true);
    return axiosInstance
      .delete(`/api/brands/${brandId}`)
      .then(() => {
        setBrands((prevBrands) =>
          prevBrands.filter((brand) => brand.id !== brandId)
        );
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error deleting brand:', error);
        setError(error);
        setLoading(false);
        throw error;
      });
  };

  return {
    brands,
    loading,
    error,
    fetchBrands,
    addBrand,
    updateBrand,
    deleteBrand,
  };
};

export default useBrands;