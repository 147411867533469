import React, { useState, useEffect } from 'react';
import useTranslations from '../../hooks/useTranslations';
import axiosInstance from '../../utils/axiosInstance';

const ManageTranslations = () => {
  const { keys, loading, error, fetchUniqueKeys, addOrUpdateTranslation } = useTranslations();
  const [selectedKey, setSelectedKey] = useState('');
  const [translations, setTranslations] = useState({ en: '', no: '' });
  const [newKey, setNewKey] = useState('');
  const [newTranslation, setNewTranslation] = useState({ en: '', no: '' });
  const [loadingTranslations, setLoadingTranslations] = useState(false); // Local loading state

  useEffect(() => {
    fetchUniqueKeys(); // No need to add this function as a dependency
  }, []);

  const handleSelectKey = (key) => {
    setSelectedKey(key);
    setLoadingTranslations(true); // Set loading state before fetching translations
  
    axiosInstance
      .get(`/api/translations/${key}`)
      .then((response) => {
        // Log response to ensure we get the expected structure
        console.log('Fetched translations:', response.data);
  
        const translationsMap = response.data.reduce((acc, item) => {
          acc[item.language_code] = item.value;
          return acc;
        }, {});
  
        // Set default values for missing languages (en, no)
        setTranslations({
          en: translationsMap.en || '',
          no: translationsMap.no || '',
        });
  
        setLoadingTranslations(false); // Clear loading state after fetching
      })
      .catch((error) => {
        console.error('Error fetching translations for key:', error);
        setLoadingTranslations(false); // Clear loading state on error
      });
  };  

  const handleTranslationChange = (lang, value) => {
    setTranslations((prevTranslations) => ({
      ...prevTranslations,
      [lang]: value,
    }));
  };

  const handleSaveTranslation = () => {
    Object.entries(translations).forEach(([language_code, value]) => {
      addOrUpdateTranslation({ language_code, key: selectedKey, value });
    });
  };

  const handleAddNewTranslation = () => {
    Object.entries(newTranslation).forEach(([language_code, value]) => {
      addOrUpdateTranslation({ language_code, key: newKey, value });
    });
    setNewKey('');
    setNewTranslation({ en: '', no: '' });
  };

  if (loading || loadingTranslations) {
    return <div>Loading translations...</div>;
  }

  if (error) {
    return <div>Error loading translations: {error.message}</div>;
  }

  return (
    <div className="p-4 max-w-6xl mx-auto">
      <h2 className="text-3xl font-bold mb-6 text-center">Manage Translations</h2>
      
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 mb-6">
        <div>
          <h3 className="text-xl font-semibold mb-4">Existing Keys</h3>
          <ul className="list-disc pl-5 space-y-2 bg-gray-50 p-4 rounded-md shadow">
            {keys.map((key) => (
              <li
                key={key}
                className="cursor-pointer hover:text-blue-500"
                onClick={() => handleSelectKey(key)}
              >
                {key}
              </li>
            ))}
          </ul>
        </div>
  
        {selectedKey && (
          <div className="lg:col-span-2 bg-gray-50 p-6 rounded-md shadow">
            <h3 className="text-xl font-semibold mb-4">Edit Translations for Key: {selectedKey}</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block font-medium mb-1">English</label>
                <input
                  type="text"
                  value={translations.en}
                  onChange={(e) => handleTranslationChange('en', e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div>
                <label className="block font-medium mb-1">Norwegian</label>
                <input
                  type="text"
                  value={translations.no}
                  onChange={(e) => handleTranslationChange('no', e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
            </div>
            <button
              onClick={handleSaveTranslation}
              className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 w-full md:w-auto"
            >
              Save Translation
            </button>
          </div>
        )}
      </div>
  
      <div className="bg-gray-50 p-6 rounded-md shadow">
        <h3 className="text-xl font-semibold mb-4">Add New Translation Key</h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div>
            <label className="block font-medium mb-1">New Key</label>
            <input
              type="text"
              value={newKey}
              onChange={(e) => setNewKey(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md"
            />
          </div>
          <div>
            <label className="block font-medium mb-1">English</label>
            <input
              type="text"
              value={newTranslation.en}
              onChange={(e) => setNewTranslation((prev) => ({ ...prev, en: e.target.value }))}
              className="w-full p-2 border border-gray-300 rounded-md"
            />
          </div>
          <div>
            <label className="block font-medium mb-1">Norwegian</label>
            <input
              type="text"
              value={newTranslation.no}
              onChange={(e) => setNewTranslation((prev) => ({ ...prev, no: e.target.value }))}
              className="w-full p-2 border border-gray-300 rounded-md"
            />
          </div>
        </div>
        <button
          onClick={handleAddNewTranslation}
          className="mt-4 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 w-full md:w-auto"
        >
          Add Translation
        </button>
      </div>
    </div>
  );  
};

export default ManageTranslations;