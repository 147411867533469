import React, { useState, useEffect, useContext } from 'react';
import ThemeForm from './ThemeForm';
import useThemes from '../../hooks/useThemes';
import { FaPlus, FaEdit, FaTrash } from 'react-icons/fa';

const ManageThemes = () => {
  const {
    themes,
    loading,
    error,
    fetchThemes,
    deleteTheme,
  } = useThemes();
  
  const [selectedTheme, setSelectedTheme] = useState(null);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    fetchThemes();
  }, []);

  const handleAddTheme = () => {
    setSelectedTheme(null);
    setShowForm(true);
  };

  const handleEditTheme = (theme) => {
    setSelectedTheme(theme);
    setShowForm(true);
  };

  const handleDeleteTheme = (themeId) => {
    if (window.confirm('Are you sure you want to delete this theme?')) {
      deleteTheme(themeId)
        .then(() => {
          // Optionally show a success message
        })
        .catch((error) => {
          console.error('Error deleting theme:', error);
        });
    }
  };

  const handleFormClose = () => {
    setShowForm(false);
    setSelectedTheme(null);
    fetchThemes(); // Refresh the theme list
  };

  if (loading) {
    return <div className="text-center text-blue-500">Loading themes...</div>;
  }

  if (error) {
    return <div className="text-center text-red-500">Error loading themes: {error.message}</div>;
  }

  return (
    <div className="p-8 bg-white rounded-lg shadow-lg">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-gray-800">Manage Themes</h2>
        <button 
          onClick={handleAddTheme} 
          className="flex items-center bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition duration-300"
        >
          <FaPlus className="mr-2" /> Add Theme
        </button>
      </div>
      <div className="overflow-x-auto overflow-y-auto max-h-96">
        <table className="min-w-full bg-white">
          <thead>
            <tr className="w-full bg-gray-200 text-left">
              <th className="p-4 font-semibold text-gray-700">ID</th>
              <th className="p-4 font-semibold text-gray-700">Name</th>
              <th className="p-4 font-semibold text-gray-700">Language</th>
              <th className="p-4 font-semibold text-gray-700">Header Color</th>
              <th className="p-4 font-semibold text-gray-700">Actions</th>
            </tr>
          </thead>
          <tbody>
            {themes.map((theme) => (
              <tr key={theme.id} className="hover:bg-gray-100 transition duration-300">
                <td className="p-4 border-b border-gray-200 text-gray-800">{theme.id}</td>
                <td className="p-4 border-b border-gray-200 text-gray-800">{theme.name}</td>
                <td className="p-4 border-b border-gray-200 text-gray-800">{theme.languageCode}</td>
                <td className="p-4 border-b border-gray-200 text-gray-800">{theme.headerColor}</td>
                <td className="p-4 border-b border-gray-200 text-gray-800">
                  <button
                    onClick={() => handleEditTheme(theme)}
                    className="flex items-center text-yellow-500 hover:text-yellow-600 mr-4"
                  >
                    <FaEdit className="mr-1" /> Edit
                  </button>
                  <button
                    onClick={() => handleDeleteTheme(theme.id)}
                    className="flex items-center text-red-500 hover:text-red-600"
                  >
                    <FaTrash className="mr-1" /> Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showForm && (
        <div className="mt-6">
          <ThemeForm theme={selectedTheme} onClose={handleFormClose} />
        </div>
      )}
    </div>
  );
};

export default ManageThemes;