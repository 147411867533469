import { useContext, useState, useEffect } from 'react';
import axiosInstance from '../utils/axiosInstance';
import { AppContext } from '../context/AppContext';

// Custom hook to manage themes
const useThemes = () => {
  const { setLanguageCode, setHeaderColor } = useContext(AppContext);
  const [themes, setThemes] = useState([]);
  const [currentTheme, setCurrentTheme] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch all themes from the server
  const fetchThemes = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get('/api/themes');
      setThemes(response.data);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  // Fetch a single theme by ID
  const fetchThemeById = async (id) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`/api/themes/${id}`);
      setCurrentTheme(response.data);
      applyTheme(response.data); // Apply theme to AppContext
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  // Create a new theme
  const createTheme = async (themeData) => {
    setLoading(true);
    try {
      const response = await axiosInstance.post('/api/themes', themeData);
      setThemes([...themes, response.data]);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  // Update an existing theme by ID
  const updateTheme = async (id, updatedTheme) => {
    setLoading(true);
    try {
      const response = await axiosInstance.put(`/api/themes/${id}`, updatedTheme);
      setThemes(themes.map(theme => (theme.id === id ? response.data : theme)));
      applyTheme(response.data); // Apply updated theme to AppContext
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  // Delete a theme by ID
  const deleteTheme = async (id) => {
    setLoading(true);
    try {
      await axiosInstance.delete(`/api/themes/${id}`);
      setThemes(themes.filter(theme => theme.id !== id));
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  // Apply the theme to update AppContext (e.g., language, colors, etc.)
  const applyTheme = (theme) => {
    if (theme.languageCode) setLanguageCode(theme.languageCode);
    if (theme.headerColor) setHeaderColor(theme.headerColor);
    // You can add more logic to update other AppContext values like images
  };

  return {
    themes,
    currentTheme,
    loading,
    error,
    fetchThemes,
    fetchThemeById,
    createTheme,
    updateTheme,
    deleteTheme,
    applyTheme,
  };
};

export default useThemes;