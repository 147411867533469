import React from 'react';
import useTranslations from '../hooks/useTranslations'; // Assuming you want to translate the field label

const PrintPatternDisplay = ({ field, value }) => {
  const { t } = useTranslations();
  
  // Positions of the dots in a 3x3 grid
  const dots = [
    { id: 1, x: 50, y: 50 },
    { id: 2, x: 150, y: 50 },
    { id: 3, x: 250, y: 50 },
    { id: 4, x: 50, y: 150 },
    { id: 5, x: 150, y: 150 },
    { id: 6, x: 250, y: 150 },
    { id: 7, x: 50, y: 250 },
    { id: 8, x: 150, y: 250 },
    { id: 9, x: 250, y: 250 },
  ];

  // Ensure the pattern is a valid array
  const pattern = Array.isArray(value) ? value : [];

  return (
    <div className="print-pattern-display pattern-lock-container">
      <label className="form-label">{t(field.name)}</label>
      <svg width="180" height="180" className="pattern-lock">
        {/* Render lines between connected dots */}
        {pattern.map((dotId, index) => {
          if (index === 0) return null;
          const fromDot = dots.find((d) => d.id === pattern[index - 1]);
          const toDot = dots.find((d) => d.id === dotId);
          return (
            <line
              key={`line-${index}`}
              x1={fromDot.x}
              y1={fromDot.y}
              x2={toDot.x}
              y2={toDot.y}
              stroke="blue"
              strokeWidth="5"
            />
          );
        })}

        {/* Render the dots */}
        {dots.map((dot) => {
          const indexInPattern = pattern.indexOf(dot.id);
          let fillColor = '#ccc'; // Default color for unselected dots

          if (indexInPattern !== -1) {
            if (indexInPattern === 0) {
              fillColor = 'green'; // Starting dot
            } else if (indexInPattern === pattern.length - 1) {
              fillColor = 'red'; // Ending dot
            } else {
              fillColor = 'blue'; // Intermediate dots
            }
          }

          return (
            <circle key={`dot-${dot.id}`} cx={dot.x} cy={dot.y} r="15" fill={fillColor} />
          );
        })}
      </svg>
    </div>
  );
};

export default PrintPatternDisplay;