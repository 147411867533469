import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import HomePage from './pages/HomePage';
import PrintPage from './pages/PrintPage';  // PrintPage component for printing
import AdminPage from './pages/AdminPage';
import './App.css';

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          {/* Routes */}
          <Route path="/" element={<HomePage />} />
          <Route path="/admin/*" element={<AdminPage />} />
          <Route path="/print" element={<PrintPage />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;